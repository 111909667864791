<template>
    <section id="section-home">
        <div id="main-bg-image" class="mainbgimg bgimg"
             :style="`background-image: url(` + require('@/assets/images/home/bg.jpg') + `)`">
        </div>
        <div id="main-bg-image-1" class="mainbgimg bgimg"
             :style="`background-image: url(` + require('@/assets/images/home/bg-1.png') + `)`">
        </div>
        <div id="main-bg-image-2" class="mainbgimg bgimg"
             :style="`background-image: url(` + require('@/assets/images/home/bg-2.png') + `)`">
        </div>
        <div id="main-bg-image-3" class="mainbgimg bgimg"
             :style="`background-image: url(` + require('@/assets/images/home/bg-3.png') + `)`">
        </div>

        <div class="quotes-holder-outer">
            <!--<QuotesHolder :quotes="quotes"></QuotesHolder>-->
        </div>

        <div id="main-bg-image-mobile" >
            <img src="@/assets/images/home/bg-mobile.jpg"/>
        </div>
        <div class="con">

            <div class="panel-outer">
                <div class="panel">
                    <div class="panel2">
                        <!--<div class="tt stagger1"><img src="@/assets/images/home/tt.png"/></div>-->
                        <div class="dates stagger1">
                            <div class="l-small style2" v-html="thedate"></div>
                            <div class="l-big">#HOUNDSOFWAR</div>
                            <!--

                            -->
                            <!--
                            <div class="l-small">
                                <button @click="openAction">
                                    watch now on demand
                                </button>
                            </div>
                            -->
                        </div>


                        <button class="ytplayer stagger1 Zdeactivated" @click="gh.openOverlay('video')">
                            <!--
                            <div class="bg0">
                                <img class="bg-frame0" src="@/assets/images/home/player-frame.png"/>
                            </div>
                            -->
                            <div class="bg">
                                <img class="bg-frame" src="@/assets/images/home/player.jpg"/>
                            </div>
                            <div class="icon stagger1">
                                <div class="icon2">
                                    <inline-svg :src="require('@/assets/images/home/btn-play.svg')"/>
                                </div>
                            </div>
                        </button>

                        <div class="pre-order-box stagger1" >
                            <span v-html="thedateButton"></span>
                        </div>



                        <!--
                        <button class="btn stagger1" @click="openAction('vod')" v-html="thedateButton">
                        </button>
                        <div class="l3 style2 stagger1">Sign up for updates & exclusive content.</div>
                        <div class="l3 signup stagger1">
                            <EmailSignup
                                mcDomain="https://lullabymovieofficial.us20.list-manage.com"
                                mcUserId="b25b645d846865015940afb4d"
                                mcListId="f713ffe268"
                            ></EmailSignup>
                        </div>

                        <div class="l3 button-row stagger1">
                            <div class="button-row-l">
                                <span>#Subservience</span>
                            </div>

                            <div class="button-row-r">
                                <a href="https://www.dropbox.com/scl/fo/xqrru9a879puiqxm84efi/AA_fxLqHdMUumpqy2PB3L8M?rlkey=iv59kp4ylmk12kn06xq8itq2k&e=1&st=56j43q44&dl=0" target="_blank" class="btn-carrot full-width">
                                    <span>PRESS/EPK</span>
                                </a>
                            </div>

                        </div>
                        -->


                        <div class="left-side-bugs stagger1">
                            <button class="bug">
                                <a href="https://tv.apple.com/us/movie/hounds-of-war/umc.cmc.22wexcsx0ob9mnevn07h136pj" target="_blank">
                                    <img src="@/assets/images/order/apple-tv.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.amazon.com/Hounds-War-Isaac-Florentine/dp/B0D155S5G2/ref=sr_1_1?crid=3CBYGKUB3Z2W1&dib=eyJ2IjoiMSJ9.7hF1jz3brMxt-tSrLpoLHgUVSRX1frvYT9ek5x0d-detAGuuZGJ37-Eb26jA5oEGupGLY_1vkwnLusXmcoPctKyd3xDMAuWeV7nru3m3ls3GI_fi1jXwaPnRq26sdS4b8MsIR4CAOMajH4GvJTwSP-fDVFc2daCmN7W4XG1llCJiHSOedRZJhor7So3N2srJImIBlp-Y5MAgYf31YWZSKZvpQtXgWDznwIDcUpjYPvo.1tifoaQJC9TtiE3QqskgS19bEm-cTGNm6A5Q7s52ylo&dib_tag=se&keywords=hounds+of+war&qid=1725630730&s=instant-video&sprefix=hounds+of+war%2Cinstant-video%2C113&sr=1-1" target="_blank">
                                    <img src="@/assets/images/order/amazon.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.vudu.com/content/browse/details/Hounds-of-War/3438172" target="_blank">
                                    <img src="@/assets/images/order/fandango.png"/>
                                </a>
                            </button>
                            <!--
                            <button class="bug">
                                <a href="#" target="_blank">
                                    <img src="@/assets/images/order/vudu.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="#" target="_blank">
                                    <img src="@/assets/images/order/itunes.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="#" target="_blank">
                                    <img src="@/assets/images/order/amazon.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="#" target="_blank">
                                    <img src="@/assets/images/order/redbox.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="#" target="_blank">
                                    <img src="@/assets/images/order/google.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="#" target="_blank">
                                    <img src="@/assets/images/order/xbox.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="#" target="_blank">
                                    <img src="@/assets/images/order/prime.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="#" target="_blank">
                                    <img src="@/assets/images/order/fandango.png"/>
                                </a>
                            </button>
                            -->
                        </div>


                        <div class="l3 left-side-icons stagger1" style="margin-top: 20px">

                            <div class="social-icons">
                                <div class="icon social">
                                    <a href="https://twitter.com/XYZFilms" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/twitter.svg')"/>
                                    </a>
                                </div>
                                <div class="icon social">
                                    <a href="https://www.facebook.com/xyzfilms/" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/facebook-f.svg')"/>
                                    </a>
                                </div>
                                <div class="icon social">
                                    <a href="https://www.instagram.com/xyzfilms/" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/instagram.svg')"/>
                                    </a>
                                </div>
                                <div class="icon social">
                                    <a href="https://www.youtube.com/channel/UC82a54ONoesmTcQInXvFl9A?sub_confirmation=1" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/youtube.svg')"/>
                                    </a>
                                </div>

                            </div>
                        </div>

                        <div class="l3 left-side-icons stagger1">
                            <div class="social-icons">
                                <div class="icon studio">
                                    <a href="https://www.xyzfilms.com/" target="_blank">
                                        <img src="@/assets/images/home/xyz.png"/>
                                    </a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

        </div>

        <div id="order" :class="[(isOpen) ? 'open' : '']">
            <div @click="closeAction" class="scrim-closer"></div>
            <div class="order-inner">
                <div class="order-inner2">
                    <!--
                    <div class="bgimg"
                         :style="`background-image: url(` + require('@/assets/images/popup/tt-bg.jpg') + `)`">
                    </div>
                    -->
                    <div @click="closeAction" class="close-button-outer">
                        <div class="close-button"></div>
                    </div>
                    <div class="order-inner3">
                        <!--<div class="tt">
                            <img src="@/assets/images/home/tt.png"/>
                        </div>
                        -->

                        <div v-if="this.bugsType==='theaters'" id="bugs-theaters" class="bugs">

                        </div>
                        <div v-if="this.bugsType==='vod'" id="bugs-vod" class="bugs">
                            <!--
                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/itunes.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>

                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/amazon.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://www.amazon.com/Backspot-Devery-Jacobs/dp/B0CW4ZC4M5" target="_blank">
                                    <img src="@/assets/images/order/amazon.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://tv.apple.com/us/movie/backspot/umc.cmc.6n9zfqyt5eq9msu4ej1qsbhxd"
                                   target="_blank">
                                    <img src="@/assets/images/order/apple-tv.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://www.xfinity.com/stream/entity/7518903800086714112" target="_blank">
                                    <img src="@/assets/images/order/xfinity.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://www.vudu.com/content/browse/details/Backspot/3150395" target="_blank">
                                    <img src="@/assets/images/order/fandango-at-home.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://play.google.com/store/movies/details/Backspot?id=bHzAf9a8ccw.P" target="_blank">
                                    <img src="@/assets/images/order/google.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://ondemand.spectrum.net/movies/27257837/backspot/" target="_blank">
                                    <img src="@/assets/images/order/spectrum.svg"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://tv.verizon.com/watch/movie/Backspot/MV021993720000?idType=TMS&qualifiedId=TMS-MV021993720000" target="_blank">
                                    <img src="@/assets/images/order/verizon.png"/>
                                </a>
                            </button>
                            <button class="bug xnum-1">
                                <a href="https://www.microsoft.com/en-US/p/_/8D6KGWXX1FX9" target="_blank">
                                    <img src="@/assets/images/order/microsoft.png"/>
                                </a>
                            </button>



                             <button class="bug comingsoon">
                                <img src="@/assets/images/order/microsoft.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>
                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/itunes.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>

                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/vudu.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>
                            <button class="bug">
                                <a href="https://tv.apple.com/movie/mind-body-spirit/umc.cmc.22r86hpogqaoxbg340icpqy0i?itsct=tv_box_link&itscg=30200" target="_blank">
                                    <img src="@/assets/images/order/apple-tv.png"/>
                                </a>
                            </button>





                            <button class="bug">
                                <a href="https://www.vudu.com/content/browse/details/Mind-Body-Spirit/3111538" target="_blank">
                                    <img src="@/assets/images/order/vudu.png"/>
                                </a>
                            </button>

                            <button class="bug">
                                <a href="https://www.microsoft.com/en-us/p/mind-body-spirit/8d6kgwxww0wk?activetab=pivot%3aoverviewtab" target="_blank">
                                    <img src="@/assets/images/order/xbox.png"/>
                                </a>
                            </button>

                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/amazon.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>

                            <button class="bug comingsoon">
                                <img src="@/assets/images/order/google.png"/>
                                <div class="comingsoon2">Coming Soon</div>
                            </button>



                            <button class="bug">
                                <a href="https://www.regmovies.com/movies/hunt-her-kill-her/ho00013977#/" target="_blank">
                                    <img src="@/assets/images/order/regal.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.amctheatres.com/movies/hunt-her-kill-her-71905" target="_blank">
                                    <img src="@/assets/images/order/amc.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.fandango.com/hunt-her-kill-her-2023-231039/movie-overview?date=2023-03-02"
                                   target="_blank">
                                    <img src="@/assets/images/order/fandango.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.laemmle.com/film/hunt-her-kill-her#get-tickets"
                                   target="_blank">
                                    <img src="@/assets/images/order/lamelle.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.readingcinemasus.com/rohnertpark/film/hunt-her-kill-her"
                                   target="_blank">
                                    <img src="@/assets/images/order/reading.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href="https://www.consolidatedtheatres.com/kaahumanu"
                                   target="_blank">
                                    <img src="@/assets/images/order/consolidated.png"/>
                                </a>
                            </button>
                            <button class="bug">
                                <a href=" https://lumierecinemala.com/film-hunt-her-kill-her/"
                                   target="_blank">
                                    <img src="@/assets/images/order/lumiere.png"/>
                                </a>
                            </button>
                            -->

                        </div>
                        <div class="hash">
                            #Subservience
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
import gh from "@/helpers/gh"
//import EmailSignup from '@/components/blocks/EmailSignup'
//import QuotesHolder from '@/components/blocks/QuotesHolder'

export default {
    name: "HomePage",
    components: {
        //EmailSignup,
        //QuotesHolder
    },
    data() {
        return {
            gh: gh,
            window: window,
            isOpen: false,
            bugsType: null,
            quotes: [
                {
                    quote: "",
                    source: ""
                },
            ]
        }
    },
    mounted() {
        this.window.addEventListener('keyup', this.escapeButtonPressed)
    },
    computed: {
      thedate() {
          let today = new Date()
          //today = new Date('2024-09-9')
          let ret = ''
          if (today >= new Date('2024-09-20')) {
              ret = 'NOW PLAYING ON DIGITAL';
          } else if (today >= new Date('2024-09-19')) {
              ret = 'TOMORROW';
          } else if (today >= new Date('2024-09-16')) {
              ret = 'FRIDAY';
          } else {
              ret = 'SEPTEMBER 20TH';
          }
          return ret
      },
        thedateButton() {
            let today = new Date()
            let ret = ''
            if (today >= new Date('2024-09-20')) {
                ret = 'WATCH NOW';
            } else {
                ret = 'PRE-ORDER NOW';
            }
            return ret
        },
    },

    methods: {
        escapeButtonPressed(e) {
            if (e.key === "Escape") {
                this.closeAction()
            }
        },
        openAction(type) {
            this.bugsType = type
            this.isOpen = true
        },
        closeAction() {
            this.isOpen = false
        }
    }
}
</script>